import React from 'react';
import {Link} from 'react-router-dom';
import BottomLink from '../../components/BottomLink';
import ZoomableImage from '../../components/ZoomableImage';

function SuiteBee() {
  return (
    <div className="bww-page page-work"><div className="bww-section section-case-studies">
    <div className="case-study">
      <h2>SuiteBee
      <Link className="cs-nav cs-nav-prev" to="/work-lenovo">&#9650;</Link>
      <Link className="cs-nav cs-nav-next" to="/work-crossriver">&#9660;</Link>
      </h2>
      <div className="bww-row">
        <img className="bww-col-1up" src="./images/case-studies/sb-hero.jpg" alt="SuiteBee.io Dashboard" />
      </div>
      <p>My visionary co-worker at The Woo had a brilliant idea: instead of endlessly creating one-off landing pages for clients, create a platform where they could be constructed quickly, then charge server & subscription fees. Rooted in WordPress, SuiteBee offers a few key advantages:</p>
      <ul>
        <li>Clients can update content with ease, while having restricted access governed by tiered user permissions.</li>
        <li>We developers can create new suites of features using WP's comprehensive plug-in architecture, and roll them out periodically.</li>
      </ul>
      <p>Some of my notable contributions:</p>
      <div className="bww-row">
      <ZoomableImage className="bww-col-3up" image="./images/case-studies/sb-vid1.jpg" alt="SuiteBee Video Features" />
      <ZoomableImage className="bww-col-3up" image="./images/case-studies/sb-vid2.jpg" alt="SuiteBee Video Features" />
      <ZoomableImage className="bww-col-3up" image="./images/case-studies/sb-vid3.jpg" alt="SuiteBee Video Features" />
      </div>
      <h3>1. Video Streaming</h3>
      <p>Files in multiple formats are uploaded and transcoded to streaming formats (MPEG-DASH and HLS). Clients are then able to use a whitelabel streaming player with configurable properties.</p>
      <p>In the admin area, I hacked a collaboration plugin to enable team members to leave timecoded comments. When viewing the video here, these clickable markers appear & disappear in place within the player interface.</p>

      <div className="bww-row">
      <ZoomableImage className="bww-col-2up" image="./images/case-studies/sb-embed1.jpg" alt="SuiteBee Embed Features" />
      <ZoomableImage className="bww-col-2up" image="./images/case-studies/sb-embed2.jpg" alt="SuiteBee Embed Features" />
      </div>
      <h3>2. Embedder</h3>
      <p>Some clients require content that can reside natively inside their own webpages, within their own domains. I've seen other solutions that rely on iframes, but that's a shortcut that harms SEO and other considerations.</p>
      <p>I engineered a backend process that "pushes" content on demand. Behind the scenes it accomplishes a lot of tasks: it crawls CSS and JavaScript assets and places them inline (better performance); it converts CSS selectors so they're sandboxed inside the embedding DOM element (thus not disrupting the host page); it manages DOM transformations as needed; provides error fallbacks, and more.</p>
      <p>Client-side developers need only supply a DOM selector for embedding element, then copy a short embed script for page inclusion. If they make a mistake, we're able to correct it server-side without changing the embed code.</p>

      <div className="bww-row">
      <ZoomableImage className="bww-col-3up" image="./images/case-studies/sb-chat-form.jpg" alt="SuiteBee Chat Features" />
      <ZoomableImage className="bww-col-3up" image="./images/case-studies/sb-chat-tree.jpg" alt="SuiteBee Chat Features" />
      <ZoomableImage className="bww-col-3up" image="./images/case-studies/sb-chat-mockup.jpg" alt="SuiteBee Chat Features" />
      </div>
      <h3>3. Chatbots</h3>
      <p>Clients can script a chatbot that lives on any page. Optionally integrating with Google DialogFlow, users can click buttons or enter freeform text (e.g. "I'd like to see your handbags").</p>
      <p>Intuitive but comprehensive, a dynamic form quickly defines each interaction along the user's journey, then a cool GUI creates a tree diagram that traces the whole flow of the conversation.</p>

      <p className="techblock">React/Redux, NodeJS, MPEG-DASH/HLS Video, PHP, WordPress hooks &amp; filters, WP-REST API, AWS (Lambdas, Cloud9, Elastic Transcolder, S3), DialogFlow, AngularJS, MySQL, miscellaneous JavaScript libraries, various integrations, HTML5, CSS3/SASS.</p>
    </div>
    <BottomLink to="/work-crossriver" label="Cross River App" />
    </div></div>
  );
}

export default SuiteBee;
