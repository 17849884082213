import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {NavContext} from './Contexts';
import './css/Header.css';
import MiddleLink from './components/MiddleLink';

class Header extends Component {
  constructor( props ) {
    super(props);
    this.state = { navOpen: false }
  }

  render() {
    const loc =this.props.location.pathname;
    return (
        <NavContext.Consumer>
          {({navIsOpen, openNav, closeNav, toggleNav}) => (
            <div className="bww-header">
              <div className="burger-bg" />
              <div className={`bww-header-nav-open ${navIsOpen ? 'banished' : ''}`} onClick={ toggleNav }>
                <span className="hamburger hamburger-0" /><span className="hamburger hamburger-1" /><span className="hamburger hamburger-2" />
              </div>
              <div className={`no-select bww-header-nav ${navIsOpen ? 'nav-open' : ''}`}>
                <nav onClick={() => {closeNav();return true;}}>
                  <p><MiddleLink to="/">Dave</MiddleLink></p>
                  <p><MiddleLink to="/work">Case Studies</MiddleLink></p>
                  <p className={`cs-sub ${ (loc === '/work-rnf' || loc === '/work') ? 'active' : '' }`}><MiddleLink to="/work-rnf">Nixon Foundation</MiddleLink></p>
                  <p className={`cs-sub ${ (loc === '/work-lenovo') ? 'active' : '' }`}><MiddleLink to="/work-lenovo">Lenovo Game State</MiddleLink></p>
                  <p className={`cs-sub ${ (loc === '/work-suitebee') ? 'active' : '' }`}><MiddleLink to="/work-suitebee">SuiteBee</MiddleLink></p>
                  <p className={`cs-sub ${ (loc === '/work-crossriver') ? 'active' : '' }`}><MiddleLink to="/work-crossriver">Cross River</MiddleLink></p>
                  <p className={`cs-sub ${ (loc === '/work-nerdist') ? 'active' : '' }`}><MiddleLink to="/work-nerdist">Nerdist</MiddleLink></p>
                  <p className={`cs-sub ${ (loc === '/work-bespoke') ? 'active' : '' }`}><MiddleLink to="/work-bespoke">Bespoke WordPress</MiddleLink></p>
                </nav>
                <div className="xbww-header-nav-close" onClick={closeNav} />
              </div>
          </div>
          )}
        </NavContext.Consumer>
    );
  }
}

export default withRouter(Header);
