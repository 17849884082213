import React, {Component} from 'react';
import Modal from 'simple-react-modal';

const minZoomDisplay = 640;

class ZoomableImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible:false,
    }
  }

  render() {
    const thumb = ( undefined === this.props.thumbnail )? this.props.image : this.props.thumbnail;
    const wwidth = window.innerWidth;

    if ( wwidth < minZoomDisplay ) {
      return (
        <div className={`non-zoomable ${this.props.className}`}>
          <img src={thumb} alt={this.props.alt || 'Image'} />
        </div>);
    }

    return (
      <div className={`zoomable-image-group ${this.props.className}`}>
        <a className="zoomable-image-launcher" onClick={() => this.setState({modalVisible:true})}>
          <img src={thumb} alt={this.props.alt || 'Image'} />
          <div className="modal-open" />
        </a>
        <Modal
          transitionSpeed={50}
          className="zoomable-modal"
          containerClassName="zoomable-modal-container"
          show={this.state.modalVisible}
          onClose={() => this.setState({modalVisible:false})} >
          <img src={this.props.image} />
          <a className="modal-close" onClick={() => this.setState({modalVisible:false})} />
        </Modal>
      </div>
    );
  }
}

export default ZoomableImage;
