import React from 'react';
import Logos from '../sections/Logos';
//import {Link} from 'react-router-dom';
import MiddleLink from '../components/MiddleLink';
import '../css/Home.css';

function Home() {
  return (
    <div className="bww-page page-home">
    <div className="no-select headshot_contain"><img className="headshot" src="./images/dbushnell_drawn_401000_x1.png" alt="Photo of Dave Bushnell" /></div>
      <h3>
        <span className="bulleted">Award&#8209;winning web&nbsp;developer</span>
        <span className="bulleted">Zend&#8209;certified PHP&nbsp;engineer</span>
        <span className="bulleted">Frontend:&nbsp;React&nbsp;devotee</span>
        <span className="bulleted">Mobile&nbsp;app&nbsp;developer</span>
        <span className="bulleted">WordPress&nbsp;customization expert</span>
        <span className="bulleted">Full&#8209;stack&nbsp;developer</span>
        <span className="bulleted">Decades&nbsp;of&nbsp;experience</span>
      </h3>
      <p className="bww-question">Who am I?</p>
      <p className="bww-answer">I'm an award-winning developer experienced in high-profile websites, apps and other digital properties. I work best alongside designers and visionaries to create excellent user experiences, providing a strong pragmatic yang to their imaginitive yin.</p>

      <p className="bww-question">What does a (good) developer do?</p>
      <p className="bww-answer">My job has several components:</p>
      <ul>
        <li><i>I participate in the pitching and planning process.</i> I use my knowledge of tech and trends to strategize features that will win / benefit the client.</li>
        <li><i>I build the foundation.</i> I collaborate with designers and draw up wireframes that best define the user's journey.</li>
        <li><i>I write the code.</i> I strive to write it efficiently, elegantly, and readably.</li>
        <li><i>I safeguard, optimize and comply.</i> I ensure the job complies with applicable regulations, that it's optimized for things like performance and SEO, and that it's served quickly.</li>
        <li><i>I keep up.</i> During downtime, I make sure to familiarize myself with emerging trends, and learn new skills.</li>
      </ul>

      <p className="bww-question">Am I proficient in ________?</p>
      <p className="bww-answer">Probably. I've learned a lot of libraries, frameworks and languages. My biggies include React/React Native, PHP/MySQL and WordPress, out of a long and growing list.</p>
      <p className="bww-answer">But if you're scanning for a certain keyword, you're likely asking the wrong question: a good developer knows how to learn necessary skills quickly, spending time on learning a tech that's proportional to its impact on the project or workflow. If I haven't encountered it before, I know how to get up to speed rapidly.</p>
      <p className="bww-answer">Furthermore, the digital landscape is constantly shifting. My job is to stay on top of the trends, and constantly learn new skills. For instance, I was once a celebrated Flash Actionscripter &mdash; How much work would I be getting now, if I didn't change with the times?</p>
      <p className="bww-answer"><MiddleLink to="/work">Feel free to peruse my case studies;</MiddleLink> I've listed the major technologies used in each.</p>

      <p className="bww-question">Why should you hire an experienced developer?</p>
      <p className="bww-answer">Developers are like musicians. They can play music, but do they make you bob your head and tap your feet?</p>
      <p className="bww-answer"><i>Big picture.</i> All developers are prone to tunnel vision, but rookies are especially so, since they're preoccupied with code syntax &mdash; it takes a long time for coding to become second nature. I'm always aware of how one component fits into the big picture.</p>
      <p className="bww-answer"><i>Robustness.</i> It's easy to code something that works when conditions are ideal; veteran coders routinely allow for glitches, errors, and unknown circumstances.</p>
      <p className="bww-answer"><i>Ownership.</i> Junior developers blithely include arbitrary packages, rather than spending half an hour writing the right function. Dependencies and plugins are great if you manage them, but if you're blind to the code underlying your project it's hard to fix problems.</p>
      <p className="bww-answer"><i>Compliance.</i> The job isn't done when it simply works. Digital properties &mdash; especially websites &mdash; must comply with regulations, laws and best practices. Plus, SEO and performance are key to making a product competitive.</p>
      <p className="bww-answer"><i>Security.</i> I'm often shocked at how many professionals are unaware of common attack vectors.</p>
      <p className="bww-answer"><i>Finesse.</i> How do those transitions look? Effortless and polished, or clumsy and jarring? I take pride not only in the visual elements, but also in writing good code.</p>
      <p className="bww-answer"><i>Mentorship.</i> Software docs and tutorials cover usage and syntax, but I can look back on years of projects, code components, head-scratching language idiosyncrasies, and mistakes from which I've learned greatly.</p>

      <p className="bww-question">Am I a designer?</p>
      <p className="bww-answer">Nope. There are lots of talented digital designers out there, and I love being delighted by their visions. But I have a background in design, and indeed I've designed cool smaller sites. I have a keen aesthetic eye, and I collaborate exceptionally well with visual thinkers.</p>

      <p className="bww-question">Anything else you should know about me?</p>
      <p className="bww-answer">I enjoy lecturing, presenting and teaching; I've been described as "inspirational." I'm a co-organizer of <a href="https://www.meetup.com/TechFaithful/" target="_blank">TechFaithful, a tech-focused meetup</a>, where I sometimes teach code skills to attendees.</p>
      <p className="bww-answer">One of my proudest accomplishments at my last job was teaching the creative team how to animate HTML5 banner ads. I even helped some of them earn DoubleClick certifications.</p>
      <p className="bww-answer"><a href="https://www.c-span.org/video/?c4844066/user-clip-dave-bushnell-nixonfoundationorg" target="_blank">View a video clip</a> where I present a high-profile website at its launch.</p>

      <p style={{textAlign:'center'}}><br /><i>Code samples and references available on request</i></p>
      <Logos />
    </div>
  );
}

export default Home;
