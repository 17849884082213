import React from 'react';
import {Link} from 'react-router-dom';

function MiddleLink({to, className,children}) {
  return (
    <Link className={`middle-link ${className}`} to={to} onClick={prepLeave}>{children}</Link>
  );
}

const prepLeave = () => {
  if ( !document ) { return; }
  const st = document.body.scrollTop || document.documentElement.scrollTop;
  const sh = document.body.scrollHeight || document.documentElement.scrollHeight;
  const transOrg = Math.round(100 * st / (sh - window.innerHeight));
  const leavingEl =document.querySelector('.bww-page');
  leavingEl.style.marginTop = `-${st}px`;
  leavingEl.style.transformOrigin = `50% ${transOrg}%`;
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

export default MiddleLink;
