import React from 'react';
import '../css/Logos.css';

function importAllImages( r ) {
  return r.keys().map(k => {
    const match = k.match( /^\.\/([^\.]+)/);
    return {name:match[1], data:r(k)};
  });
}

const imgFiles = importAllImages( require.context('../images/logos/', true, /\.(png|jpg)$/));

function Logos() {
  return (
    <div className="bww-section section-logos">
      <p>Some past clients</p>
      <div className="logo-cells">
        {imgNames.map( (imgName,ix) => <LogoCell
          imgData={imgFiles.find( ob => (ob.name === imgName) )}
          key={`logo-cell-${ix}`}
          />)}
      </div>
    </div>
  );
}

const LogoCell = ({imgData}) => (
  <div className="logo-cell-contain">
    <div className="logo-cell" style={{
      backgroundImage: `url(${imgData.data})`
    }} />
  </div>
);

const imgNames = [
  "lenovo",
  "panasonic",
  "motorola",
  "bosch",
  "braun",
  "delonghi",
  "toyota",
  "honda",
  "nbc",
  "dreamworks",
  "telemundo",
  "weather",
  "mastercard",
  "pepsimax",
  "sierramist",
  "niagara",
  "mission",
  "coldstone",
  "verizon",
  "vonage",
  "metro",
  "net10",
  "rnf",
  "nerdist",
  "pixologic",
  "disneyland",
  "seaworld",
  "legoland",
  "canada-tourism",
  "tourism-australia",
  "travel-nevada",
  "calottery",
  //"garzagroup",

];

export default Logos;
