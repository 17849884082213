import React from 'react';
import {Link} from 'react-router-dom';
import BottomLink from '../../components/BottomLink';
import ZoomableImage from '../../components/ZoomableImage';

function Nerdist() {
  return (
    <div className="bww-page page-work"><div className="bww-section section-case-studies">
    <div className="case-study">
      <h2>Nerdist
      <Link className="cs-nav cs-nav-prev" to="/work-crossriver">&#9650;</Link>
      <Link className="cs-nav cs-nav-next" to="/work-bespoke">&#9660;</Link>
      </h2>
      <div className="bww-row">
        <img className="bww-col-1up" src="./images/case-studies/nerdist-hero.jpg" alt="Nerdist.com Website" />
      </div>
      <p>Brainchild of supreme nerd Chris Hardwick, this site was once Alexa-ranked as high as 1200 in the U.S.</p>
      <p>At the time I was tasked with its new launch, it seemed ubiquitous. Every social media feed in America seemed to include some stories from Nerdist.</p>
      <div className="bww-row">
        <ZoomableImage className="bww-col-3up" image="./images/case-studies/nerdist0.jpg" alt="Nerdist.com screenshot" />
        <ZoomableImage className="bww-col-3up" image="./images/case-studies/nerdist-mockup.jpg" alt="Nerdist.com screenshot" />
        <ZoomableImage className="bww-col-3up" image="./images/case-studies/nerdist1.jpg" alt="Nerdist.com screenshot" />
      </div>
      <p>Previously, the site existed as an overtaxed off-the-shelf WordPress theme, so I created a wholly original theme and migrated tens of thousands of posts &mdash; plus audio and video podcasts &mdash; from old site to new. Importantly, the new design included ad units integrated seamlessly in its grid layout.</p>
      <p>On the admin side, I empowered the client with many tools for searching and managing large data sets, which hadn't previously been possible.</p>
      <p className="techblock">PHP, wholly customized WordPress, MySQL, JSON/REST APIs, miscellaneous JavaScript libraries, various integrations, HTML5, CSS3/SASS.</p>
    </div>
    <BottomLink to="/work-bespoke" label="Bespoke WordPress" />
    </div></div>
  );
}

export default Nerdist;
