import React, {Component} from 'react';
import {Switch,Route, withRouter} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import RNF from './sections/case-studies/RNF';
import Lenovo from './sections/case-studies/Lenovo';
import SuiteBee from './sections/case-studies/SuiteBee';
import CrossRiver from './sections/case-studies/CrossRiver';
import Nerdist from './sections/case-studies/Nerdist';
import Bespoke from './sections/case-studies/Bespoke';

import styled from 'styled-components';
import Home from './pages/Home';
//import Work from './pages/Work';
import gear from './images/gear2w.png';
import trackleft from './images/track2il_e6.png';
import trackright from './images/track2ir_e6.png';
import './css/Structure.css';
import './css/CaseStudies.css';

const minGearDisplay = 640;

class Structure extends Component {
  constructor( props ) {
    super(props);
    this.province = {
      rerenderTO:null,
      autoplaying:false
    }
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  handleScroll() {
    if ( undefined === window ) { return; }
    if ( window.innerWidth < minGearDisplay ) { return; }
    const st = document.body.scrollTop || document.documentElement.scrollTop;
    const deg = 360 * st / 300;
    document.querySelectorAll('.gearbox img').forEach( (el,ix) => {
      el.style.transform = (ix % 2) ? `translateX(-50%) translateY(-50%) rotate(${deg}deg)` : `translateX(-50%) translateY(-50%) rotate(${0-deg}deg)`;
    })
    //if ( !this.province.autoplaying ) {
      this.autoplays();
      //this.province.autoplaying = true;
    //}
  }

  handleResize() {
    if ( this.province.rerenderTO ) {
      clearTimeout( this.province.rerenderTO );
    }
    this.province.rerenderTO = setTimeout( () => this.forceUpdate(), 900 );
  }

  autoplays() {
    if ( undefined === document ) { return; }
    const autoplayers = document.querySelectorAll('video[autoplay]');
    autoplayers.forEach( el => {
      el.removeAttribute('autoPlay');
      el.play()} )
  }

  componentDidMount() {
    if ( undefined === window ) { return; }
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize );
  }

  componentWillUnmount() {
    if ( undefined === window ) { return; }
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize );
    clearTimeout( this.province.rerenderTO );
  }

  gearboxes() {
    if ( undefined === window ) { return null; }
    let output = [];
    const nn = 2 * Math.floor((window.innerHeight + 152) / 200);
    for ( let n=0; n < nn; n++ ) {
      output.push(
        <div className={`no-select gearbox gearbox-${(n % 2) ? 'right' : 'left'}`} id={`gearbox${n}`} key={`gearbox${n}`}
          style={{
            top: `${48 + (Math.floor(n/2)) * 200}px`
          }}
        >
          <img alt="gear" src={gear} style={{transform:'rotate(0deg) translateX(-50%) translateY(-50%)'}} />
        </div>
      )
    }
    return output;
  }

  render() {
    const keyName = (this.props.location && this.props.location.pathname.indexOf('/work') === 0 ) ? 'worksite' : 'homesite';
    return (
      <StructWrap className="bww-structure">
        {(keyName === 'worksite')? <h1 key={keyName}>Case <br className="discre" />Studies</h1> : <h1 key={keyName}>Dave <br className="discre" />Bushnell</h1>}
        <TransitionGroup>
          <CSSTransition
            key={this.props.location.key}
            timeout={{ enter:400, exit:450 }}
            classNames={`pagetrans`}
          >
            <Switch location={this.props.location}>
            <Route path="/" component={ Home } exact />
            <Route path="/index.html" component={ Home } />
            <Route path="/work" component={ RNF } />
            <Route path="/work-rnf" component={ RNF } />
            <Route path="/work-lenovo" component={ Lenovo } />
            <Route path="/work-suitebee" component={ SuiteBee } />
            <Route path="/work-crossriver" component={ CrossRiver } />
            <Route path="/work-nerdist" component={ Nerdist } />
            <Route path="/work-bespoke" component={ Bespoke } />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        {this.gearboxes()}
      </StructWrap>
    );
  }
}

const StructWrap = styled.div`
  background-color:#FFF;
  position:relative;
  ::before {
    background-image:url(${trackleft});
    background-position:left 4px;
    left:0;
  }
  ::after {
    background-image:url(${trackright});
    background-position:right 4px;
    left:auto;
    right:0;
  }
`;

export default withRouter(Structure);
