import React from 'react';
import Header from './Header';
import Structure from './Structure';
import Footer from './Footer';
import {NavContext} from './Contexts';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navIsOpen:false,
      toggleNav: () => {
        this.setState({ navIsOpen: !this.state.navIsOpen})
      },
      openNav: () => {
        this.setState({ navIsOpen: true})
      },
      closeNav: () => {
        this.setState({ navIsOpen: false})
      },
    }
  }
  render() {
    return (
      <NavContext.Provider value={this.state}>
        <main>
          <Header />
          <Structure />
          <Footer />
        </main>
      </NavContext.Provider>
    );
  }
}

export default App;
