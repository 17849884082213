import React from 'react';
import {Link} from 'react-router-dom';

function BottomLink({to,label}) {
  return (
    <p className="next-cs"><Link to={to} onClick={prepLeave}>&#9660;&nbsp;{label}</Link></p>
  );
}

const prepLeave = () => {
  if ( !document ) { return; }
  const st = document.body.scrollTop || document.documentElement.scrollTop;
  const leavingEl =document.querySelector('.bww-page');
  leavingEl.style.marginTop = `-${st}px`;
  leavingEl.style.transformOrigin = '50% 100%';
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

export default BottomLink;
