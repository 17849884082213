import React from 'react';
import {Link} from 'react-router-dom';
import BottomLink from '../../components/BottomLink';
import ZoomableImage from '../../components/ZoomableImage';

function RNF() {
  return (
    <div className="bww-page page-work"><div className="bww-section section-case-studies">
    <div className="case-study">
      <h2>Richard Nixon Foundation
      <Link className="cs-nav cs-nav-prev" to="/work-bespoke">&#9650;</Link>
      <Link className="cs-nav cs-nav-next" to="/work-lenovo">&#9660;</Link>
      </h2>
      <div className="bww-row">
        <img className="bww-col-1up" src="./images/case-studies/rnf-hero.jpg" alt="Nixon Foundation Homepage" />
      </div>
      <p>In 2016, the Nixon Foundation installed a multimillion-dollar exhibit showcasing the president's life, and set aside a six-figure budget for a new website to coincide with its opening.</p>
      <p>At the time, I observed there were multitude sources of information about Nixon in different media, in scattered places. I pitched the RNF on creating a single research hub where all these media files could be catalogued, categorized, tagged, searched and viewed.</p>
      <p>The website was a huge success. I wrote a crawler that scraped metadata from some 12,000 documents, images, videos and audio files (including historic White House tapes), and created a WordPress custom post type (an "artifact") for each. Curators were able to craft online exhibits in many formats, using any number of artifacts.</p>
      <p>For the first time ever, researchers and scholars worldwide were able to browse rich, cross-referenced, multimedia information from a single source.</p>
      <div className="bww-row">
        <ZoomableImage className="bww-col-3up" image="./images/case-studies/rnf-1.jpg" alt="NixonFoundation.org screenshot" />
        <ZoomableImage className="bww-col-3up" image="./images/case-studies/rnf-2.jpg" alt="NixonFoundation.org screenshot" />
        <ZoomableImage className="bww-col-3up" image="./images/case-studies/rnf-3.jpg" alt="NixonFoundation.org screenshot" />
      </div>
      <p>In addition, I worked with a talented designer to create a sophisticated, beautiful, multilingual website for the Nixon Library and its new exhibit.</p>
      <p>Personally, I found the deep dive into history a fascinating journey. Furthermore, it was a rare privilege to work with some of the people who played roles in the world's most powerful office at the time.</p>
      <p><a className="hilite" href="https://www.c-span.org/video/?c4844066/user-clip-dave-bushnell-nixonfoundationorg" target="_blank">View a CSPAN clip</a> in which I present the new website to boardmembers, donors, VIPs and family at its launch.</p>

      <div className="bww-row">
        <img className="bww-col-1up" src="./images/case-studies/rnf-history.jpg" alt="Nixon Foundation Homepage" />
      </div>

      <p className="techblock">PHP, wholly customized WordPress, WooCommerce, MySQL, AWS (EC2, RDS, S3, CloudFront), JSON/REST APIs, multilingual content, miscellaneous JavaScript libraries, various integrations, HTML5, CSS3/SASS.</p>
      <img aria-hidden="true" src="https://www.bushnellwebworks.com/anon2020.php?w=rnf" style={{width:'1px',height:'1px',opacity:0}} />
    </div>
    <BottomLink to="/work-lenovo" label="Lenovo Game State" />
    </div>
    </div>
  );
}

export default RNF;
