import React from 'react';
import {Link} from 'react-router-dom';
import BottomLink from '../../components/BottomLink';
import ZoomableImage from '../../components/ZoomableImage';

function Bespoke() {
  return (
    <div className="bww-page page-work"><div className="bww-section section-case-studies">
    <div className="case-study">
      <h2>Bespoke WordPress
      <Link className="cs-nav cs-nav-prev" to="/work-nerdist">&#9650;</Link>
      <Link className="cs-nav cs-nav-next" to="/work-rnf">&#9660;</Link>
      </h2>
      <div className="bww-row">
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-mc.jpg" alt="Mastercard site screenshot" />
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-am0.jpg" alt="Americana Manhasset site screenshot" />
      </div>
      <p>Since 2011 I've developed wholly-customized WordPress themes and plugins (from scratch) for clients. I used to maintain a <a href="http://www.wpcraftsman.com" target="_blank">WordPress development blog</a>, and much of the information is still valid.</p>
      <div className="bww-row">
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-munoz.jpg" alt="Nino Munoz site screenshot" />
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-paris.jpg" alt="Paris Photografix site screenshot" />
      </div>
      <p>In case you didn't know, WordPress is the world's most popular content management system. Originally conceived as a blogging platform, it has evolved into a platform that can drive practically any kind of content. With a comprehensive system of hooks and filters, developers can tap into its rich features and provide a wealth of functionality.</p>
      <div className="bww-row">
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-rika.jpg" alt="Nerdist.com screenshot" />
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-aniari.jpg" alt="Nerdist.com screenshot" />
      </div>

      <p>Most other developers use shortcuts when working with WordPress. They modify pre-manufactured themes to suit a particular need. They rely heavily on prefab plugins which require constant maintenance and updates. Or &mdash; shockingly &mdash; they modify core files to accomplish a task.</p>
      <p>These shortcuts are problematic for many reasons:</p>
      <p><b><i>First,</i></b> they force you to compromise &mdash; your design won't look precisely as you hoped because you're using someone else's theme; you'll have to resort to workarounds to accomplish tasks out of reach of plugins.</p>
      <p><b><i>Second,</i></b> they make the site more vulnerable to failure. When WordPress, or any single theme / plugin is updated, other themes and plugins can break.</p>
      <p><b><i>Third,</i></b> security is a concern. You've essentially yielded control of your site to third-party developers, some of whom may not have a fundamental grasp of code vulnerabilities. I recently worked on a multi-user project which employed a visual page builder plugin, and it ran arbitrary PHP code. I pointed out: one savvy user could use that feature to gain access to the entire database, so that plugin was scrapped.</p>

      <div className="bww-row">
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-staffords.jpg" alt="Nerdist.com screenshot" />
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-govida.jpg" alt="Nerdist.com screenshot" />
      </div>

      <p>For my bespoke WordPress customizations, I tailor the theme to suit the designers' precise intentions. And I always provide comprehensive tools in the admin interface, to manage content seamlessly.</p>
      <p>Commonly I'll provide drag &amp; drop tools to order items, and to create slideshows, galleries, carousels and more. Or I'll enrich WordPress' search and reporting features so information is better organized and accessed.</p>

      <div className="bww-row">
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-adamas.jpg" alt="Nerdist.com screenshot" />
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-wilhelm.jpg" alt="Nerdist.com screenshot" />
      </div>

      <p>When processing large datasets, WordPress needs help. Data beyond the basics (title, content, date, author) is usually stored in a catchall "metadata" table; each row has a "name" column for the type of metadata within. Imagine a spreadsheet where, instead of columns calling out all values like "subtotal", "tax" and "terms", it had rows with a single value column, and a label in a single "name" column. The job of calculating would be much, much harder, right?</p>
      <p>I know how to create unique relational tables, index them properly, and tailor them to the data. Once I took over a job from inexperienced developers using standard WordPress metadata tables, in which a certain report view took more than half an hour to generate. I reduced that time to 70 seconds.</p>

      <div className="bww-row">
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-transcenders.jpg" alt="Nerdist.com screenshot" />
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/besp-fahey.jpg" alt="Nerdist.com screenshot" />
      </div>

      <p className="techblock">PHP, wholly customized WordPress, E-commerce (WooCommerce, Shopify, PayPal, "Homegrown"), MySQL, JSON/REST APIs, jQuery, Bootstrap, miscellaneous JavaScript libraries, various integrations, HTML5, CSS3/SASS.</p>
    </div>
    <BottomLink to="/work-rnf" label="Richard Nixon Foundation" />
    </div></div>
  );
}

export default Bespoke;
