import React from 'react';
import {Link} from 'react-router-dom';
import BottomLink from '../../components/BottomLink';
import ZoomableImage from '../../components/ZoomableImage';

function CrossRiver() {
  return (
    <div className="bww-page page-work"><div className="bww-section section-case-studies">
    <div className="case-study">
      <h2>Cross River App
      <Link className="cs-nav cs-nav-prev" to="/work-suitebee">&#9650;</Link>
      <Link className="cs-nav cs-nav-next" to="/work-nerdist">&#9660;</Link>
      </h2>
      <div className="bww-row">
        <div className="bww-col-3up cs-bordered">
          <video src="./videos/cr-app3.mp4" autoPlay="autoplay" muted="muted" loop="loop" playsInline="playsInline" />
        </div>
        <ZoomableImage className="bww-col-23up" image="./images/case-studies/cr-app1.jpg" alt="Cross River App landscape orientation" />
      </div>

      <p>This fintech company opened a number of brick & mortar branches in the NY/NJ area. The request was an in-house native iPad app enabling users to apply for accounts either onsite or at offsite events.</p>
      <p>I built this app which features delightful transitions and animations, plus an admin panel so CR personnel can review and update applicant data on the spot.</p>
      <p>Security was premium, and I engineered a PCI-compliant backend processing script and database that lives on the client's servers.</p>

      <p className="techblock">React Native, Redux, PHP, MSSQL, PCI-level security.</p>
    </div>
    <BottomLink to="/work-nerdist" label="Nerdist" />
    </div></div>
  );
}

export default CrossRiver;
