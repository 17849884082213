import React from 'react';
//import {Link} from 'react-router-dom';
import MiddleLink from './components/MiddleLink';
import "./css/Footer.css";

function Footer() {
  return (
    <div className="bww-footer">
      <div className="bww-footer-left">© 2020 Bushnell WebWorks. All rights reserved. Logos and trademarks are the property of their respective holders.</div>
      <MiddleLink to="/" className="footer-logo-link"><img src="./images/bww_logo.png" /></MiddleLink>
      <div className="bww-footer-right">
        <span className="bww-footer-span-link bww-footer-email-link" onClick={buildAddress}><img src="/images/em_ail.png" /></span>
        <span className="bww-footer-span-link bww-footer-phone-link" onClick={buildPhone} ><img src="/images/ph_one.png" /></span>
        <a href="https://www.zend-zce.com/en/yellow-pages/ZEND027082" className="bww-footer-cert-link" target="_blank">
          <img className="zce" src="./images/zce.png" />
        </a>
      </div>
    </div>
  )
}


function buildAddress() {
	const pool='abdehklnorsuvw@';
	const ixs=[2,0,12,3,14,1,11,10,4,7,3,6,6,13,3,1,13,8,9,5,10];
	let addr='';
	for (const ix in ixs) {
		addr += pool.substr(ixs[ix],1);
	}
	addr += '.com';
  window.location="mailto:"+addr+'?subject=Bushnell%20WebWorks%20Inquiry';
}

function buildPhone() {
  const pool = '6789012345';
  const ixs=[6,5,7,0,7,6,2,7,0,8]
  let phone = '';
  for (const ix in ixs) {
    phone += pool.substr(ixs[ix],1);
  }
  window.location="tel:" + phone;
}

export default Footer;
