import React from 'react';
import {Link} from 'react-router-dom';
import BottomLink from '../../components/BottomLink';
import ZoomableImage from '../../components/ZoomableImage';

function Lenovo() {
  return (
    <div className="bww-page page-work"><div className="bww-section section-case-studies">
    <div className="case-study">
      <h2>Lenovo Game State
      <Link className="cs-nav cs-nav-prev" to="/work-rnf">&#9650;</Link>
      <Link className="cs-nav cs-nav-next" to="/work-suitebee">&#9660;</Link>
      </h2>
      <div className="bww-row">
        <img className="bww-col-1up" src="./images/case-studies/lgs-hero.jpg" alt="Lenovo Game State Website" />
      </div>
      <p>To promote Lenovo's leading-edge gaming PCs, The Woo hit on a unique and newsworthy strategy: contract an indy studio to develop a tower-defense game, and incorporate crowdsourced elements. Lenovo Game State was the hub for this project, and user engagement was "gamified" &mdash; users scored points for watching promos, sharing links, up-/downvoting and submitting UGC.</p>
      <p>Every few weeks we issued a new "mission" so users could participate in new ways.</p>
      <div className="bww-row">
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/lgs-m3-1.jpg" alt="Lenovo Game State screenshot" />
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/lgs-m3-2.jpg" alt="Lenovo Game State screenshot" />
      </div>
      <p>For one mission, I coded a slot-machine mini-game that combined elements like pistols, flamethrowers, and banana peels to form a single weapon; users were asked to describe and submit its capabilities.</p>

      <div className="bww-row">
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/lgs-m4-1.jpg" alt="Lenovo Game State screenshot" />
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/lgs-m4-2.jpg" alt="Lenovo Game State screenshot" />
      </div>
      <p>For another I constructed a scene generator, and users submitted a catchphrase uttered by the hero in a battle animation. Users either recorded audio via an online interface, or entered it so compatible browsers would read it aloud (profanity filters activated).</p>

      <div className="bww-row">
        <video className="bww-col-2up" src="./videos/lgs-scroll.mp4" autoPlay="autoplay" muted="muted" loop="loop" playsInline="playsInline" />
        <ZoomableImage className="bww-col-2up" image="./images/case-studies/lgs-json.jpg" alt="Lenovo Game State JSON" />
      </div>

      <p>I engineered an infinite scroller that calculated and stacked arbitrary tiles of different sizes from a single JSON feed. It incorporated videos, interactive elements, UGC and more, so the site seemed endless and constantly fresh.</p>
      <p>When a tile was clicked, the grid split apart revealing a media player, mini-game, UGC carousel, CTA, etc.; browser location (shareable) and history was managed in an SEO-friendly manner.</p>

      <p>"Engagement" is a word that's thrown around a lot, but Game State really was a fun place to join, visit and participate.</p>
      
      <p className="techblock">PHP, MySQL, Firebase, AWS (EC2, S3, CloudFront), JSON/REST APIs, multilingual content, system audio input + JS web workers, SpeechSynthesisUtterance, Twilio, miscellaneous JavaScript libraries, various integrations, HTML5, CSS3/SASS.</p>

    </div>
    <BottomLink to="/work-suitebee" label="SuiteBee" />
    </div></div>
  );
}

export default Lenovo;
